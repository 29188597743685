<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="d-flex flex-column">
    <v-form
      ref="form"
      v-model="validForm"
      id="create-payment"
      name="create-payment"
    >
      <template>
        <div class="d-flex">
          <v-select
            class="mx-1"
            outlined
            item-text="name"
            item-value="abbreviation"
            dense
            :items="monthShow"
            v-model="body.month"
            :rules="[rules.required]"
            label="Month"
          ></v-select>
          <v-select
            outlined
            dense
            :items="yearShow"
            v-model="body.year"
            :rules="[rules.required]"
            label="Year"
          ></v-select>
        </div>

        <v-textarea
          :rules="[rules.required]"
          outlined
          label="Details"
          :disabled="creating"
          v-model.trim="body.details"
        ></v-textarea>
      </template>
    </v-form>

    <div class="d-flex fill-width">
      {{/* Add buttons here */}}
      <v-btn color="error" width="120px" depressed rounded @click="reset()">
        Cancel
      </v-btn>

      <v-spacer />
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="!validForm || loading"
        width="120px"
        depressed
        rounded
        @click="addPayment()"
      >
        {{ btnText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import PatientCard from "@/views/Patients/components/PatientCard.vue";
import { loadStripeTerminal } from "@stripe/terminal-js";

import Vue from "vue";
import rules from "@/components/account/rules";
import {
  notificationError,
  notifyError,
  notifySuccess,
  notifyInfo,
} from "@/components/Notification";
import { getAPI } from "@/api/axios-base";
import { mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
import months from "@/utils/enums/months";
export default Vue.extend({
  components: { PatientCard },
  name: "make-payment",

  data() {
    return {
      loading: false,
      rules,
      btnText: "Submit",
      confirmate: false,

      validForm: false,

      creating: false,
      discount: 0,
      body: {
        details: "",
        type: "CASH",
        month: moment().format("MMM"),
        year: moment().format("YYYY"),
      },
    };
  },

  computed: {
    ...mapState("crmMedicFormModule", ["patientDetail"]),
    monthShow() {
      return months;
    },

    yearShow() {
      return [
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
      ];
    },
    cardShow() {
      const cardN = this.patientDetail.cardNumber;
      return `****-****-****-${cardN}`;
    },

    /*  expShow() {
      const exp = this.patientDetail.cardExpireDate;
      const year = exp.split("-")[0].substr(2, 2);
      const month = exp.split("-")[1];
      return `${month}/${year}`;
    }, */
  },

  methods: {
    addPayment() {
      const body = { ...this.body, patientUuid: this.patientDetail.uuid };
      if (body.month) {
        const x = this.monthShow.find((m) => m.abbreviation == body.month);
        body.month = x.value;
      }
      body.year = Number(body.year);

      this.loading = true;
      getAPI
        .post("/patient/createPayment", body)
        .then((res) => {
          this.loading = false;
          notifyInfo("Payment Confirmed");
          this.$emit("payment");
          this.$emit("confirmed");
          this.reset();
        })
        .catch((err) => {
          this.loading = false;
          let mess = err.response?.data?.message.toString();

          notifyError(err.response.data, `An error occurred: ${mess}`);
        });

      /*  if (this.confirmate) {
        if (body.type == "Stripe") {
          if (this.epaycard && body.type == "Stripe") {
            body.type = "UsaEpay";
          }
          getAPI
            .post("/payment/confirm-payment", {
              paymentUuid: this.confirmPay.payment.uuid,
              cardId: body.cardId,
            })
            .then(() => {
              this.loading = false;
              notifyInfo("Payment Confirmed");
              this.$emit("payment");
              this.$emit("confirmed");
              this.reset();
            })
            .catch((error) => {
              this.loading = false;

              if (error.response?.status == 400) {
                notifyError(
                  error.response.data.message || error.response.data.details
                );
              } else {
                notificationError();
              }
            });
        }
      } */

      /******** */
    },
    reset() {
      this.body.details = "";
      this.btnText = "Submit";
      this.loading = false;

      this.$emit("cancel");
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.cardshow {
  font-size: 24px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.otherdates {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.bg-custom {
  margin-top: 2rem;
  font: bold 100% sans-serif;
  letter-spacing: 0.5em;
  text-align: center;
  text-transform: uppercase;
  background: #26a69a;
  border-radius: 0.25em;
  color: #fff;
  margin: 0 0 1em;
  padding: 0.5em 0;
}
</style>
